@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src:
    local('Pretendard_Regular'),
    url('./fonts/Pretendard-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src:
    local('Pretendard_Regular'),
    url('./fonts/Pretendard-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src:
    local('Pretendard_Regular'),
    url('./fonts/Pretendard-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src:
    local('Pretendard_Regular'),
    url('./fonts/Pretendard-Bold.woff') format('woff');
}

body {
  margin: 0;
  color: #1c1e20;
  font-weight: 500;
  font-family:
    'Pretendard',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
